import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_Categories = _resolveComponent("Categories")!
  const _component_Results = _resolveComponent("Results")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_Header),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_HowItWorks),
          (_ctx.searchbar.category === 0 && _ctx.searchbar.text === '')
            ? (_openBlock(), _createBlock(_component_Categories, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.searchbar.category !== 0 || _ctx.searchbar.text !== '')
            ? (_openBlock(), _createBlock(_component_Results, { key: 1 }))
            : _createCommentVNode("", true),
          _createVNode(_component_Footer)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}