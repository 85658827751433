
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import HowItWorks from "../components/HowItWorks.vue";
import Categories from "../components/Categories.vue";
import Results from "../components/Results.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { useStore } from "../store";

export default defineComponent({
  name: "Suppliers",
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    HowItWorks,
    Results,
    Categories,
  },
  data: () => {
    return {
      store: useStore(),
    };
  },
  computed: {
    searchbar(): {} {
      return this.store.state.searchbar;
    },
  },
});
