
import { IonCol, IonGrid, IonRow, IonButton, IonText } from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
  name: "Categories",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonButton,
    IonText,
  },
  data: () => {
    return {
      store: useStore(),
    };
  },
  computed: {
    categories(): [] {
      const categories = this.store.state.categories;
      return categories;
    },
  },
  methods: {
    setCategoryFilter(id: number) {
      this.store.commit("setCategoryFilter", id);
    },
  },
});
