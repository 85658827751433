<template>
  <ion-grid>
    <ion-row
      v-for="sup in supplers"
      :key="sup.id"
      class="ion-justify-content-start result"
    >
      <ion-col
        sizeXs="12"
        sizeSm="4"
        sizeMd="3"
        offsetMd="1"
        sizeLg="3"
        offsetLg="0"
        sizeXl="2"
        offsetXl="2"
        class="ion-padding"
      >
        <ion-slides class="amend-slider" :options="slideOpt">
          <ion-slide
            class="slide-content"
            :style="`background-image: url(https://suppliers.partyplace.je/storage/${sup.business_logo_path})`"
          >
          </ion-slide>
        </ion-slides>
      </ion-col>
      <ion-col
        sizeXs="12"
        sizeSm="8"
        sizeMd="6"
        sizeLg="5"
        sizeXl="3"
        class="ion-padding column-wrap result-contact mobile-no-pad-top-bot"
      >
        <ion-text class="result-title">
          <h2>{{ sup.business_name }}</h2>
          <p>
            <span>{{ sup.location_address }}</span>
          </p>
        </ion-text>
        <div class="contact">
          <a v-if="sup.telephone" :href="`tel:${sup.telephone}`">
            <img src="../assets/svg/phone.svg" />
            <span>{{ sup.telephone }}</span>
          </a>
          <a v-if="sup.email_address" :href="`mailto:${sup.email_address}`">
            <img src="../assets/svg/email.svg" />
            <span>{{ sup.email_address }}</span>
          </a>
          <a
            v-if="sup.website_address"
            :href="validateURL(sup.website_address)"
            target="_blank"
          >
            <img src="../assets/svg/website.svg" />
            <span>{{ sup.website_address }}</span>
          </a>
        </div>
        <div class="social">
          <a
            v-if="sup.facebook_address"
            :href="validateURL(sup.facebook_address)"
            target="_blank"
          >
            <img src="../assets/svg/facebook.svg" />
          </a>
          <a
            v-if="sup.instagram_address"
            :href="validateURL(sup.instagram_address)"
            target="_blank"
          >
            <img src="../assets/svg/instagram.svg" />
          </a>
          <a
            v-if="sup.youtube_address"
            :href="validateURL(sup.youtube_address)"
            target="_blank"
          >
            <img src="../assets/svg/youtube.svg" />
          </a>
        </div>
      </ion-col>
      <ion-col
        sizeXs="12"
        sizeSm="8"
        offsetSm="4"
        sizeMd="6"
        offsetMd="4"
        sizeLg="4"
        offsetLg="0"
        sizeXl="3"
        class="ion-padding result-about"
      >
        <ion-text color="primary">
          <h2>ABOUT US</h2>
        </ion-text>
        <ion-text class="result-text">
          <p>
            {{ sup.about }}
          </p>
        </ion-text>
        <ion-button
          @click="
            () => openModal(sup.id, sup.business_logo_path, sup.business_name)
          "
          color="success"
        >
          <span>Add To Party</span>
          <img src="../assets/svg/white-plus.svg" />
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonSlides,
  IonSlide,
  IonText,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import AddToBasket from "../components/AddToBasket";
import { useStore } from "../store";

export default defineComponent({
  name: "Results",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonButton,
    IonSlides,
    IonSlide,
    IonText,
  },
  data: () => {
    return {
      store: useStore(),
      slideOpts: {
        slidesPerView: 1,
        initialSlide: 1,
        speed: 400,
        snap: true,
      },
    };
  },
  computed: {
    supplers() {
      return this.store.getters.filterSuppliers();
    },
  },
  methods: {
    async openModal(id, image, name) {
      const modal = await modalController.create({
        component: AddToBasket,
        cssClass: "addtobasket",
        componentProps: {
          title: "Add To Party",
          image,
          name,
          id,
        },
      });
      return modal.present();
    },
    validateURL(link) {
      if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
        // console.log("The link has http or https.");
      } else {
        // console.log("The link doesn't have http or https.");
        link = "http://" + link;
      }
      return link;
    },
  },
});
</script>

<style>
.result {
  font-size: 18px;
  font-family: "grold-regular";
  padding: 1rem 0;
}

.result:nth-child(even) {
  background-color: #fafafa;
}

.result-about {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.result-about ion-button {
  margin-top: auto;
  margin-right: auto;
}

.result-about ion-button img {
  margin-left: 1rem;
}

.result-about h2 {
  letter-spacing: 1px;
  font-size: 16px;
}

.social {
  display: flex;
  align-items: center;
}

.social a {
  display: block;
  margin-right: 1rem;
}

.result-contact {
  padding-left: 1rem;
}

.result-title h2 {
  font-family: "grold-bold";
  font-size: 32px;
  margin-bottom: 5px;
}

.result-title span,
.result-text p {
  color: var(--ion-color-medium);
}

.column-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact a {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: black;
  font-weight: normal;
  text-decoration: none;
}

.contact a span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact a span:active,
.contact a span:hover {
  white-space: nowrap;
  overflow: unset;
  text-overflow: ellipsis;
}

.contact a img {
  margin-right: 1rem;
}

.swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid #fff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: var(--ion-color-primary);
  border: none;
}

ion-backdrop {
  background-color: var(--ion-color-primary);
  --ion-backdrop-opacity: 0.75;
  --backdrop-opacity: 0.78;
}

@media screen and (max-width: 990px) {
  .result-contact {
    margin-left: 0;
  }

  .contact {
    margin: 1rem 0;
  }

  .mobile-no-pad-top-bot {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>