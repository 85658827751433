
import { IonContent, IonTitle, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
  name: "AddToBasket",
  components: { IonContent, IonTitle },
  props: {
    title: { type: String },
    image: { type: String },
    name: { type: String },
    id: { type: Number },
  },
  data: () => {
    return {
      store: useStore(),
      notes: "",
    };
  },
  methods: {
    addToPartyBag() {
      this.store.dispatch("addToPartyBag", {
        id: this.id,
        notes: this.notes,
      });
      modalController.dismiss();
    },
  },
});
